import React, { useState, Outlet, useEffect } from "react";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import Head from '../../components/head/Head';
import Content from '../../components/content/Content';
import OrderOverview from "../../assets/components/partials/crypto/order-overview/OrderOverview";
import ActionCenter from "../../assets/components/partials/crypto/action-center/ActionCenter";
import CoinOrder from "../../assets/components/partials/crypto/coin-order/CoinOrder";
import UserActivity from "../../assets/components/partials/crypto/user-activity/UserActivity";
import axios from "../../api/axios";
import AudienceOverview from "../../assets/components/partials/analytics/audience-overview/AudienceOverview";
import BalanceOverview from "./components/balance_equity_chart/ChartOverview";
import AccountStatistik from "./components/AccountStatistik/AccountStatistik";
import moment from 'moment';
import Alertsection from "./components/Alerts/Alertsection";
import Alert_test from "./components/Alerts/Alert_test";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import OrderActivity from "./components/Trade_Table/Tradestable";
import Trades_Table from "./components/Trade_Table/TradesTable_new";
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockBetween,
    BlockTitle,
    PreviewAltCard,
    Icon,
    Button,
    Row,
    Col,
    TooltipComponent
} from "../../assets/components/Component";
import AccountMargin from "./components/Margin/AccountMargin";
import AccountDrawdown from "./components/Drawdown/AccountDrawdown";
import ftt_account_return_table from "./components/FTT_Return_Table/ftt_account_return";
import Ftt_account_return from "./components/FTT_Return_Table/ftt_account_return";
import Killswitch from "./components/Kill_Switch/killswitch";
import Killswitchtest from "./components/Kill_Switch/killswitchtest";
import Account_Statement from "./components/account_statement/account_statement";



const BALANCE_VIEW = '/api/rtm/balance_view/';
const DEPOSIT_VIEW = '/api/rtm/deposit_view/';
const ALERT_HISTORY = '/api/rtm/alert_hist/';

const SpecificAccount = () => {
    const { account_id } = useParams();
    const navigate = useNavigate();
    const [sm, updateSm] = useState(false);
    const data = "Hello Everyone";
    const [balance, setBalance] = useState(0);
    const [profit_week, setProfitweek] = useState(0);
    const [profit_month, setProfitmonth] = useState(0);
    const [total_return, setTotalreturn] = useState(0);
    const [week_return, setWeekreturn] = useState(0);
    const [month_return, setMonthreturn] = useState(0);
    const [total_deposit, setTotalDeposit] = useState(0);
    const [total_withdrawal, setTotalWithdrawal] = useState(0);
    const [week_deposit, setWeekDeposit] = useState(0);
    const [week_withdrawal, setWeekwithdrawal] = useState(0);
    const [month_deposit, setMonthDeposit] = useState(0);
    const [month_withdrawal, setMonthwithdrawal] = useState(0);
    const [alert_history, setAlerthistory] = useState([]);
    const params = useParams()
    const [roles, setRoles] = useState([]);
    const [accountdict, setaccountdict] = useState(JSON.parse(localStorage.getItem("accounts")));



    function currencyFormat(amount) {
        var amount_object;
        try {
            amount_object = (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        catch (e) {
            console.log(e);
            amount_object = amount;
        }
        return amount_object
    }

    useEffect(() => {
        async function fetchBalanceData() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                    setRoles(localStorage.getItem('Roles'));
                }
                const response = await axios.get(BALANCE_VIEW + params.userId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                setBalance(await response?.data[0].balance);
                setProfitweek(await response?.data[0].profit_week);
                setProfitmonth(await response?.data[0].profit_month);
                setTotalreturn(await response?.data[0].return_total);
                setMonthreturn(await response?.data[0].monthly_return);
                setWeekreturn(await response?.data[0].weekly_return);

            } catch (e) {
                console.log(e);
            }

        }

        async function fetchDepositData() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(DEPOSIT_VIEW + params.userId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                setTotalDeposit(await response?.data.deposit_total);
                setWeekDeposit(await response?.data.deposit_week);
                setMonthDeposit(await response?.data.deposit_month);
                setTotalWithdrawal(await response?.data.withdawal_total);
                setWeekwithdrawal(await response?.data.withdawal_week);
                setMonthwithdrawal(await response?.data.withdawal_month);


            } catch (e) {
                console.log(e);
            }

        }



        async function fetchAlerthistory() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(ALERT_HISTORY + params.userId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                if(roles.includes(5150))
                {
                    setAlerthistory(await response?.data);
                }



            } catch (e) {
                console.log(e);
            }

        }

        fetchBalanceData();
        fetchDepositData();
        fetchAlerthistory();


    }, []);

    function changeTimeZone(date, timeZone) {
        if (typeof date === 'string') {
            return new Date(
                new Date(date).toLocaleString('en-US', {
                    timeZone,
                }),
            );
        }
    }

    return (
        <React.Fragment>
            <Head title="Account Details" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Account {params.userId} - {accountdict[params.userId]}</BlockTitle>
                            <div className="nk-block-head-sub"><a className="back-to" href="/accounts"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    navigate(-1);
                                }}>
                                <em className="icon ni ni-arrow-left"></em><span>Accounts</span></a></div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <Row className="g-gs">
                        <Col md="4">
                            <PreviewAltCard className="card-full">
                                <div className="card-title-group align-start mb-0">
                                    <div className="card-title">
                                        <h6 className="subtitle">Account Overview - Balance</h6>
                                    </div>
                                    <div className="card-tools">
                                        <TooltipComponent
                                            iconClass="card-hint"
                                            icon="help-fill"
                                            direction="left"
                                            id="invest-deposit"
                                            text="Current Balance, Total Return, Weekly and Monthly Profit + Return"
                                        ></TooltipComponent>
                                    </div>
                                </div>
                                <div className="card-amount">
                                    <span className="amount">
                                        {currencyFormat(balance)} <span className="currency currency-usd">EUR</span>
                                    </span>
                                    {total_return >= 0 ? (
                                        <span className="change up text-success">
                                            <Icon name="arrow-long-up"></Icon>{currencyFormat(total_return * 100)}%</span>
                                    ) : (
                                        <span className="change down text-danger">
                                            <Icon name="arrow-long-down"></Icon>{currencyFormat(total_return * 100)}%</span>
                                    )}



                                </div>
                                <div className="invest-data">
                                    <div className="invest-data-amount g-2">
                                        <div className="invest-data-history">
                                            <div className="title">This Month</div>
                                            <span className="amount">
                                                {currencyFormat(profit_month)} <span className="currency currency-usd"> EUR</span>
                                                {month_return >= 0 ? (
                                                    <span className="change up text-success">
                                                        <Icon name="arrow-long-up"></Icon>{currencyFormat(month_return * 100)}%</span>
                                                ) : (
                                                    <span className="change down text-danger">
                                                        <Icon name="arrow-long-down"></Icon>{currencyFormat(month_return * 100)}%</span>
                                                )}


                                            </span>
                                        </div>
                                        <div className="invest-data-history">
                                            <div className="title">This Week</div>
                                            <span className="amount">
                                                {currencyFormat(profit_week)} <span className="currency currency-usd"> EUR</span>

                                                {week_return >= 0 ? (
                                                    <span className="change up text-success">
                                                        <Icon name="arrow-long-up"></Icon>{currencyFormat(week_return * 100)}%</span>
                                                ) : (
                                                    <span className="change down text-danger">
                                                        <Icon name="arrow-long-down"></Icon>{currencyFormat(week_return * 100)}%</span>
                                                )}


                                            </span>
                                        </div>
                                    </div>
                                    <div className="invest-data-ck">
                                        {/*<DepositBarChart />*/}
                                    </div>
                                </div>
                            </PreviewAltCard>
                        </Col>

                        <Col md="4">
                            <PreviewAltCard className="card-full">
                                <div className="card-title-group align-start mb-0">
                                    <div className="card-title">
                                        <h6 className="subtitle">Deposits in Account</h6>
                                    </div>
                                    <div className="card-tools">
                                        <TooltipComponent
                                            iconClass="card-hint"
                                            icon="help-fill"
                                            direction="left"
                                            id="invest-balance"
                                            text="Total Deposits"
                                        ></TooltipComponent>
                                    </div>
                                </div>
                                <div className="card-amount">
                                    <span className="amount">
                                        {currencyFormat(total_deposit)} <span className="currency currency-usd">EUR</span>
                                    </span>
                                </div>
                                <div className="invest-data">
                                    <div className="invest-data-amount g-2">
                                        <div className="invest-data-history">
                                            <div className="title">This Month</div>
                                            <div className="amount">
                                                {currencyFormat(month_deposit)} <span className="currency currency-usd">EUR</span>
                                            </div>
                                        </div>
                                        <div className="invest-data-history">
                                            <div className="title">This Week</div>
                                            <div className="amount">
                                                {currencyFormat(week_deposit)} <span className="currency currency-usd">EUR</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invest-data-ck">

                                        {/* <BalanceBarChart /> */}


                                    </div>
                                </div>
                            </PreviewAltCard>
                        </Col>

                        <Col md="4">
                            <PreviewAltCard className="card-full">
                                <div className="card-title-group align-start mb-0">
                                    <div className="card-title">
                                        <h6 className="subtitle">Withdrawals from Account</h6>
                                    </div>
                                    <div className="card-tools">
                                        <TooltipComponent
                                            iconClass="card-hint"
                                            icon="help-fill"
                                            direction="left"
                                            id="invest-balance-withdrawal"
                                            text="Total Withdrawals"
                                        ></TooltipComponent>
                                    </div>
                                </div>
                                <div className="card-amount">
                                    <span className="amount">
                                        {currencyFormat(total_withdrawal)} <span className="currency currency-usd">EUR</span>
                                    </span>
                                </div>
                                <div className="invest-data">
                                    <div className="invest-data-amount g-2">
                                        <div className="invest-data-history">
                                            <div className="title">This Month</div>
                                            <div className="amount">
                                                {currencyFormat(month_withdrawal)} <span className="currency currency-usd">EUR</span>
                                            </div>
                                        </div>
                                        <div className="invest-data-history">
                                            <div className="title">This Week</div>
                                            <div className="amount">
                                                {currencyFormat(week_withdrawal)} <span className="currency currency-usd">EUR</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invest-data-ck">

                                        {/* <BalanceBarChart /> */}


                                    </div>
                                </div>
                            </PreviewAltCard>
                        </Col>


                        {/*<Col md="12" xxl="8">

                            <PreviewAltCard className="card-full">
                                <Killswitch></Killswitch>
                                <Killswitchtest></Killswitchtest>
                            </PreviewAltCard>
                        </Col>*/}

                        {roles.includes(5150)  || roles.includes(1335) || roles.includes(1339) ? (
                            <Col md="12" xxl="8">
                                {/*<InvestPlan />*/}
                                <PreviewAltCard className="card-full">
                                    <BalanceOverview></BalanceOverview>
                                </PreviewAltCard>
                            </Col>

                        ) : (
                            <div></div>
                        )}



                        <Col md="12" xxl="8">
                            {/*<InvestPlan />*/}

                            <Card className="card-bordered card-full">
                                <AccountStatistik></AccountStatistik>
                            </Card>

                        </Col>

                        {alert_history.length > 0 ? (

                            <Col md="6" xxl="4">
                                <PreviewAltCard className="card-full">
                                    {/*<InvestOverview />*/}
                                    <div className="card-title-group align-start mb-0">
                                        <div className="card-title">
                                            <h6 className="subtitle">Latest Alerts</h6>
                                        </div>
                                    </div>

                                    <ul className="nk-activity">

                                        {alert_history.map((item, key) => {
                                            return (
                                                <li key={key} className="nk-activity-item">
                                                    <div className="nk-activity-data">
                                                        <div className="label">{item.alert_name}</div>
                                                        <div className="timeline-des"><span>{item.message_tx}</span></div>
                                                        <span className="time">{moment(changeTimeZone(item.alerttime, 'Europe/Berlin')).fromNow(true)} ago</span></div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </PreviewAltCard>
                            </Col>
                        ) : (
                            <div></div>
                        )}



                    </Row>
                    <Row className="g-gs">
                        <Col xl="12" xxl="8">
                            <Card className="card card-bordered">
                                <Ftt_account_return />
                            </Card>
                        </Col>
                    </Row>
                    <Row className="g-gs">
                        <Col xl="12" xxl="8">
                            <Card className="card-bordered card-full">
                                <Account_Statement />
                            </Card>
                        </Col>
                    </Row>
                    <Row className="g-gs">
                        <Col xl="12" xxl="8">
                            <Card className="card-bordered card-full">
                                <Trades_Table />
                            </Card>
                        </Col>
                    </Row>



                    {roles.includes(5150) || roles.includes(1339) ? (
                        <Row className="g-gs">

                            <Col md="6" xxl="4">
                                {/*<InvestPlan />*/}
                                <PreviewAltCard className="card-full">
                                    <AccountMargin></AccountMargin>
                                </PreviewAltCard>
                            </Col>
                            <Col md="6" xxl="4">
                                {/*<InvestPlan />*/}
                                <PreviewAltCard className="card-full">
                                    <AccountDrawdown></AccountDrawdown>
                                </PreviewAltCard>
                            </Col>

                        </Row>
                    ) : (
                        <div></div>
                    )}

                    {roles.includes(5150) || roles.includes(1339) ? (
                    <Row className="g-gs">
                        <Col xl="12" xxl="8">
                            <Card className="card card-bordered">
                                <Alert_test />
                            </Card>
                        </Col>
                    </Row>
                    ) : (
                        <div></div>
                    )}

                </Block>

            </Content>
        </React.Fragment>
    );
};

export default SpecificAccount;
